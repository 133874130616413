.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block; }

.clickable {
  cursor: pointer; }

.table-header {
  background-color: #209cee !important; }

.table-header td,
th {
  color: #ffffff !important;
  text-align: center !important;
  font-weight: bold; }

.table-header-green {
  background-color: #22c65b !important; }

.table-header-green td,
th {
  color: #ffffff !important;
  text-align: center !important;
  font-weight: bold; }

.table-summary-row {
  background-color: #333333 !important;
  color: #ffffff !important;
  font-weight: bold; }

.table-expense-summary-row {
  background-color: #cccccc !important;
  color: #666666 !important;
  font-weight: bold; }

.number-cell {
  text-align: right !important; }

.date-cell {
  text-align: center !important; }

.row {
  margin-top: 5px; }

.loading-whole-page {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000; }

div.button-padding-label {
  padding-top: 44px; }

.incentive-table {
  width: 80%;
  max-width: 880px; }
